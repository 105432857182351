define("ember-date-components/templates/components/date-picker-inline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4xrM8uF/",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"date-picker date-picker--inline \",[27,\"if\",[[23,[\"options\"]],\" date-picker--options\"],null]]]],[12,\"style\",[21,\"translateX\"]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"date-picker__calendar\"],[9],[0,\"\\n\\n    \"],[1,[27,\"date-picker-navigation\",null,[[\"currentMonth\",\"minDate\",\"maxDate\",\"disableMonthPicker\",\"disableYearPicker\",\"availableYearOffset\",\"class\",\"updateMonth\"],[[23,[\"currentMonth\"]],[23,[\"minDate\"]],[23,[\"maxDate\"]],[23,[\"disableMonthPicker\"]],[23,[\"disableYearPicker\"]],[23,[\"availableYearOffset\"]],\"date-picker__header\",[27,\"action\",[[22,0,[]],\"gotoMonth\"],null]]]],false],[0,\"\\n\\n    \"],[1,[27,\"date-picker-month\",null,[[\"month\",\"selectedDates\",\"selectDate\",\"minDate\",\"maxDate\",\"startWeekOnSunday\"],[[23,[\"currentMonth\"]],[23,[\"selectedDates\"]],[27,\"action\",[[22,0,[]],\"selectDate\"],null],[23,[\"minDate\"]],[23,[\"maxDate\"]],[23,[\"startWeekOnSunday\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"date-picker__options\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"_options\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[12,\"class\",[28,[\"date-picker__options__button \",[22,1,[\"classes\"]]]]],[11,\"type\",\"button\"],[9],[0,\"\\n          \"],[1,[22,1,[\"label\"]],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],[22,1,[\"action\"]],[22,1,[\"actionValue\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-date-components/templates/components/date-picker-inline.hbs"
    }
  });

  _exports.default = _default;
});