define("ember-date-components/templates/components/date-picker-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OAOzcjsU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"date-picker__header__button date-picker__header__button--previous\"],[11,\"data-test-date-picker-goto-previous-month\",\"\"],[11,\"type\",\"button\"],[9],[0,\"\\n  <\\n\"],[3,\"action\",[[22,0,[]],\"gotoPreviousMonth\"]],[10],[0,\"\\n\\n\"],[7,\"button\"],[11,\"class\",\"date-picker__header__button date-picker__header__button--next\"],[11,\"data-test-date-picker-goto-next-month\",\"\"],[11,\"type\",\"button\"],[9],[0,\"\\n  >\\n\"],[3,\"action\",[[22,0,[]],\"gotoNextMonth\"]],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"date-picker__header__title\"],[12,\"data-test-date-picker-month\",[27,\"moment-format\",[[23,[\"currentMonth\"]],\"MM\"],null]],[12,\"data-test-date-picker-year\",[27,\"moment-format\",[[23,[\"currentMonth\"]],\"YYYY\"],null]],[9],[0,\"\\n\\n  \"],[1,[27,\"date-picker-month-year-select\",null,[[\"minDate\",\"maxDate\",\"currentMonth\",\"disableMonthPicker\",\"disableYearPicker\",\"availableYearOffset\",\"gotoMonth\"],[[23,[\"minDate\"]],[23,[\"maxDate\"]],[23,[\"currentMonth\"]],[23,[\"disableMonthPicker\"]],[23,[\"disableYearPicker\"]],[23,[\"availableYearOffset\"]],[27,\"action\",[[22,0,[]],\"gotoMonth\"],null]]]],false],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-date-components/templates/components/date-picker-navigation.hbs"
    }
  });

  _exports.default = _default;
});