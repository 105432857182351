define("ember-l10n/templates/get-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8XMzAzZZ",
    "block": "{\"symbols\":[\"part\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"messageParts\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"name\"]]],null,{\"statements\":[[14,2,[[22,1,[\"text\"]],[22,1,[\"name\"]]]]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"unescapeText\"]]],null,{\"statements\":[[1,[22,1,[\"text\"]],true]],\"parameters\":[]},{\"statements\":[[1,[22,1,[\"text\"]],false]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-l10n/templates/get-text.hbs"
    }
  });

  _exports.default = _default;
});